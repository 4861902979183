import Vue from 'vue';
import {Plugins} from '@capacitor/core';

const {Storage} = Plugins;
const TOKEN_KEY = 'access_token';
const platform = Vue.prototype.$platform;

/**
 * Manage the how Access Tokens are being stored and retrieved from storage.
 *
 * Current implementation stores to localStorage. Local Storage should always be
 * accessed through this instance.
 **/
const TokenService = {
    async getToken() {
        if (platform === 'web') {
            return new Promise((resolve) => {
                resolve(localStorage.getItem(TOKEN_KEY));
            });
        } else {
            let token = await Storage.get({key: TOKEN_KEY});

            return token.value;
        }
    },

    saveToken(accessToken) {
        if (platform === 'web') {
            return new Promise((resolve) => {
                resolve(localStorage.setItem(TOKEN_KEY, accessToken));
            });
        } else {
            return Storage.set({
                key: TOKEN_KEY,
                value: accessToken
            })
        }
    },

    removeToken() {
        if (platform === 'web') {
            return new Promise((resolve) => {
                resolve(localStorage.removeItem(TOKEN_KEY));
            });
        } else {
            return Storage.remove({key: TOKEN_KEY});
        }
    }
};

export {TokenService}