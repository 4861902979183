import Vue from 'vue';
import Router from 'vue-router';
import WebSocketService from '@/services/websocket.service';
import PushNotificationsService from "@/services/push.notifications.service";
import MobileAppUpdatesService from "@/services/mobile.app.updates.service";
import {TokenService} from "@/services/storage.service";

const pathToRegexp = require('path-to-regexp');
const DefaultContainer = () => import('@/components/layout/DefaultContainer');
const Dashboard = () => import('@/views/Dashboard');
const VirtualDeviceInfo = () => import('@/views/VirtualDeviceInfo');
const VirtualDeviceEdit = () => import('@/views/VirtualDeviceEdit');
const Templates = () => import('@/views/Templates');
const Documents = () => import('@/views/Documents');
const Settings = () => import('@/views/Settings');
const Help = () => import('@/views/Help');
const AccountVerification = () => import('@/views/AccountVerification');
const ResetPassword = () => import('@/views/ResetPassword');
const Login = () => import('@/views/Login');

Vue.use(Router);

const router = new Router({
    mode: 'history',
    linkActiveClass: 'open active',
    scrollBehavior: () => ({y: 0}),
    routes: [
        {
            path: '/',
            name: 'login',
            component: Login,
            meta: {
                public: true,
                onlyWhenLoggedOut: true
            }
        },
        {
            path: '/account-verification/:token',
            component: AccountVerification
        },
        {
            path: '/reset-password/:token',
            component: ResetPassword
        },
        {
            path: '/',
            component: DefaultContainer,
            meta: {
                public: true,
                onlyWhenLoggedOut: false
            },
            children: [
                {
                    path: '/dashboard',
                    name: 'dashboard',
                    component: Dashboard,
                },
                {
                    path: '/device/:id',
                    name: 'device',
                    component: VirtualDeviceInfo
                },
                {
                    path: '/edit-device/:id',
                    name: 'edit-device',
                    component: VirtualDeviceEdit
                },
                {
                    path: '/templates',
                    name: 'templates',
                    component: Templates,
                },
                {
                    path: '/documents',
                    name: 'documents',
                    component: Documents,
                },
                {
                    path: '/settings',
                    name: 'settings',
                    component: Settings,
                },
                {
                    path: '/help',
                    name: 'help',
                    component: Help,
                },
            ]
        }
    ]
});

router.beforeEach(async (to, from, next) => {
    const isPublic = to.matched.some(record => record.meta.public);
    const onlyWhenLoggedOut = to.matched.some(record => record.meta.onlyWhenLoggedOut);
    const loggedIn = !!await TokenService.getToken();

    let re1 = pathToRegexp('/account-verification/:token');
    let re2 = pathToRegexp('/reset-password/:token');

    if (!re1.exec(to.path) && !re2.exec(to.path)) {
        if (!isPublic && !loggedIn) {
            return next({
                path: '/'
            });
        }

        //In case user is trying to open demo account but is already logged in as an other account clear token and redirect to login
        if (loggedIn && to.query.as === 'demo' && localStorage.getItem('is_demo') !== "true") {
            await TokenService.removeToken();
            return next();
        }

        //In case user is trying to login by pressing the login button but is already logged in as a demo account clear token and redirect to login
        if (loggedIn && to.query.as === 'by_login_button' && localStorage.getItem('is_demo') !== "false") {
            await TokenService.removeToken();
            return next();
        }

        if (loggedIn && WebSocketService.isInitialized() === false) {
            WebSocketService.initSocket();
        }

        if (loggedIn && PushNotificationsService.isServiceInitialized() === false) {
            PushNotificationsService.init();
        }

        //execute only if on mobile
        if ((Vue.prototype.$platform === 'android' || Vue.prototype.$platform === 'ios') && !MobileAppUpdatesService.hasCheckedForUpdates()) {
            MobileAppUpdatesService.checkForUpdates();
        }

        // Do not allow user to visit login page or register page if they are logged in
        if (loggedIn && onlyWhenLoggedOut) {
            return next('/dashboard')
        }
    }

    next();
});

export default router;
