import Vue from 'vue';
import {
    AlertPlugin, BadgePlugin, ButtonPlugin, ButtonGroupPlugin, CardPlugin, CollapsePlugin, DropdownPlugin, FormPlugin, TablePlugin, PaginationPlugin,
    FormGroupPlugin, FormInputPlugin, FormRadioPlugin, FormSelectPlugin, ImagePlugin, InputGroupPlugin, ProgressPlugin,
    LayoutPlugin, ModalPlugin, NavPlugin, NavbarPlugin, TabsPlugin, LinkPlugin, TooltipPlugin, FormCheckboxPlugin, CarouselPlugin
} from 'bootstrap-vue';

export default function registerBootstrapVueComponents() {
    Vue.use(AlertPlugin);
    Vue.use(BadgePlugin);
    Vue.use(ButtonPlugin);
    Vue.use(ButtonGroupPlugin);
    Vue.use(CardPlugin);
    Vue.use(CollapsePlugin);
    Vue.use(DropdownPlugin);
    Vue.use(FormPlugin);
    Vue.use(FormGroupPlugin);
    Vue.use(FormInputPlugin);
    Vue.use(FormRadioPlugin);
    Vue.use(FormCheckboxPlugin);
    Vue.use(FormSelectPlugin);
    Vue.use(ImagePlugin);
    Vue.use(TablePlugin);
    Vue.use(PaginationPlugin);
    Vue.use(DropdownPlugin);
    Vue.use(FormPlugin);
    Vue.use(InputGroupPlugin);
    Vue.use(LayoutPlugin);
    Vue.use(ModalPlugin);
    Vue.use(NavbarPlugin);
    Vue.use(NavPlugin);
    Vue.use(TabsPlugin);
    Vue.use(LinkPlugin);
    Vue.use(TooltipPlugin);
    Vue.use(CarouselPlugin);
    Vue.use(ProgressPlugin);
};