import Vue from 'vue';
import 'vue2-timepicker/dist/VueTimepicker.css'
import i18n from "./locales/i18n";
import VueCompositionApi from '@vue/composition-api';
import store from './store';
import axios from 'axios';
import App from './App';
import router from '@/router';
import format from './utilities/error.formatter.utility';
import VueProgressBar from 'vue-progressbar';
import VueAnalytics from 'vue-analytics'
import ApiService from './services/api.service';
import {Capacitor, Plugins} from '@capacitor/core';
import Toasted from 'vue-toasted';
import './registerServiceWorker';
import registerBootstrapVueComponents from "./utilities/bootstrap.vue.utility";

const {Device} = Plugins;

(async () => {
    i18n.locale = process.env.VUE_APP_OEM === 'pleion' ? 'it' : 'en';
    registerBootstrapVueComponents();

    Vue.use(VueCompositionApi);
    Vue.use(Toasted);
    Vue.use(VueProgressBar, {
        color: process.env.VUE_APP_OEM === 'pleion' ? '#ED1C24' : '#0089ff',
        failedColor: '#ffa315',
        thickness: '2px',
        transition: {
            speed: '0.2s',
            opacity: '0.6s',
            termination: 300
        },
        autoRevert: true,
        position: 'fixed',
        location: 'top',
        inverse: false
    });

    Vue.prototype.$OEM = process.env.VUE_APP_OEM;
    Vue.prototype.$platform = Capacitor.platform;
    Vue.prototype.$axios = axios;
    Vue.prototype.$format = format;

    let deviceInfo = await Device.getInfo();
    Vue.prototype.$uuid = deviceInfo.uuid;
    Vue.prototype.$appVersion = deviceInfo.appVersion;

    await ApiService.init(process.env.VUE_APP_API_URL + 'v1/');

    new Vue({
        el: '#app',
        router,
        store,
        i18n,
        mounted() {
            //  [App.vue specific] When App.vue is finish loading finish the progress bar
            this.$Progress.finish()
        },
        created() {
            //  [App.vue specific] When App.vue is first loaded start the progress bar
            this.$Progress.start();

            //  hook the progress bar to start before we move router-view
            this.$router.beforeEach((to, from, next) => {
                //  does the page we want to go to have a meta.progress object
                if (to.meta.progress !== undefined) {
                    let meta = to.meta.progress;
                    // parse meta tags
                    this.$Progress.parseMeta(meta)
                }
                //  start the progress bar
                this.$Progress.start();
                //  continue to next page
                next()
            });
            //  hook the progress bar to finish after we've finished moving router-view
            this.$router.afterEach((to, from) => {
                //  finish the progress bar
                this.$Progress.finish()
            })
        },

        template: '<App/>',

        components: {
            App
        }
    });

    if (process.env.NODE_ENV === 'production') {
        Vue.use(VueAnalytics, {
            id: process.env.VUE_APP_OEM === 'pleion' ? 'UA-167902832-1' : 'UA-128784932-1',
            router,
            checkDuplicatedScript: true
        });
    }
})();


