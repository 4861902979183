import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth'
import user from './modules/user'
import virtualDevice from './modules/virtual-device'
import createLogger from 'vuex/dist/logger'

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
    modules: {
        auth,
        user,
        virtualDevice
    },
    strict: debug,
    plugins: debug ? [createLogger()] : []
});
