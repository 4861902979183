import {connect} from "socket.io-client";
import {TokenService} from "./storage.service";
import store from "../store";

const WebSocketService = {
    initialized: false,

    initSocket() {
        this.initialized = true;
        this.socket = connect(process.env.VUE_APP_SOCKET_IO_URL, {resource: '/socket.io', rejectUnauthorized: false});

        this.socket.on('connect', async () => {
            this.socket.emit('auth', {token: await TokenService.getToken()});
        });

        this.socket.on('device-data-update', function (data) {
            store.dispatch('virtualDevice/onDeviceDataUpdate', data);
        });

        this.socket.on('device-errors-update', function (data) {
            store.dispatch('virtualDevice/onDeviceErrorsUpdate', data);
        });

        this.socket.on('device-parameters-update', function (data) {
            store.dispatch('virtualDevice/onDeviceParametersUpdate', data);
        });
    },

    closeSocket() {
        if (this.socket) {
            this.socket.close();
            this.initialized = false;
        }
    },

    isInitialized() {
        return this.initialized;
    }
};

export default WebSocketService;