import store from '../store'
import {TokenService} from '../services/storage.service'
import Vue from 'vue';

const ApiService = {
    async init(baseURL) {
        Vue.prototype.$axios.defaults.baseURL = baseURL;

        let token = await TokenService.getToken();
        if (token) {
            await this.setHeader();
        }

        this.mount401Interceptor();
    },

    async setHeader() {
        Vue.prototype.$axios.defaults.headers.common["Authorization"] = `Bearer ${await TokenService.getToken()}`
    },

    removeHeader() {
        Vue.prototype.$axios.defaults.headers.common = {}
    },

    get(resource) {
        return Vue.prototype.$axios.get(resource)
    },

    post(resource, data) {
        return Vue.prototype.$axios.post(resource, data)
    },

    put(resource, data) {
        return Vue.prototype.$axios.put(resource, data)
    },

    delete(resource) {
        return Vue.prototype.$axios.delete(resource)
    },

    /**
     * Perform a custom Axios request.
     *
     * data is an object containing the following properties:
     *  - method
     *  - url
     *  - data ... request payload
     **/
    customRequest(data) {
        return Vue.prototype.$axios(data)
    },

    mount401Interceptor() {
        Vue.prototype.$axios.interceptors.response.use(
            (response) => {

                return response
            },
            (error) => {

                if (error.request.status == 401) {
                    store.dispatch('auth/logout')
                }

                // If error was not 401 just reject as is
                throw error
            }
        )
    }
};

export default ApiService