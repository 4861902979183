import {UserService, AuthenticationError} from '@/services/user.service';
import router from '@/router';
import WebSocketService from '@/services/websocket.service';

const state = {
    authenticating: false,
    authenticationErrorCode: 0,
    authenticationError: ''
};

const getters = {
    authenticationErrorCode: (state) => {
        return state.authenticationErrorCode;
    },

    authenticationError: (state) => {
        return state.authenticationError;
    },

    authenticating: (state) => {
        return state.authenticating;
    }
};

const actions = {
    async login({commit}, {email, password}) {
        commit('loginRequest');

        try {
            const token = await UserService.login(email, password);
            commit('loginSuccess', token);
            router.push('/dashboard');

            return true
        } catch (e) {
            if (e instanceof AuthenticationError) {
                commit('loginError', {errorCode: e.errorCode, errorMessage: e.message})
            }

            return false
        }
    },

    async logout() {
        await UserService.logout();
        WebSocketService.closeSocket();
        router.push('/')
    }
};

const mutations = {
    loginRequest(state) {
        state.authenticating = true;
        state.authenticationError = '';
        state.authenticationErrorCode = 0;
    },

    loginSuccess(state) {
        state.authenticating = false;
    },

    loginError(state, {errorCode, errorMessage}) {
        state.authenticating = false;
        state.authenticationErrorCode = errorCode;
        state.authenticationError = errorMessage;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};