import ApiService from './api.service'
import {TokenService} from './storage.service'
import PushNotificationsService from './push.notifications.service';


class AuthenticationError extends Error {
    constructor(errorCode, message) {
        super(message);
        this.name = this.constructor.name;
        this.message = message;
        this.errorCode = errorCode;
    }
}

const UserService = {
    /**
     * Login the user and store the access token to TokenService.
     *
     * @returns access_token
     * @throws AuthenticationError
     **/
    async login(email, password) {
        const requestData = {
            method: 'post',
            url: "/login",
            data: {
                email: email,
                password: password
            }
        };

        try {
            const response = await ApiService.customRequest(requestData);
            await TokenService.saveToken(response.headers.authorization);
            await ApiService.setHeader();

            return response.headers.authorization;
        } catch (error) {
            if (error.message === 'Network Error') {
                throw new AuthenticationError(503, {type: 'NetworkError'})
            } else {
                throw new AuthenticationError(error.response.status, error.response.data)
            }
        }
    },

    /**
     * Logout the current user by removing the token from storage.
     * Will also remove `Authorization Bearer <token>` header from future requests.
     * Also remove push notifications token from backend so you won't receive requests when logged out.
     **/
    async logout() {
        try {
            if (PushNotificationsService.isServiceInitialized()) {
                await PushNotificationsService.close();
            }
            await TokenService.removeToken();
            ApiService.removeHeader();
        } catch (err) {
            console.log(err);
        }
    }
};

export default UserService

export {UserService, AuthenticationError}