//Formats validation errors received from server
export default function format(errorsList) {
    var errorsString = "";
    for (var key = 0; key < errorsList.length; key++) {
        errorsString += errorsList[key];

        //Do not add the last newline char
        if (key != errorsList.length - 1) {
            errorsString += '\n';
        }
    }

    return errorsString;
};