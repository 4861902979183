<template>
    <div>
        <meta v-if="$platform === 'ios'" name='viewport' content='initial-scale=1, viewport-fit=cover, user-scalable=no'>
        <meta v-else name="viewport" content="width=device-width, user-scalable=no, initial-scale=1"/>

        <router-view></router-view>
    </div>
</template>

<script>
    import {
        Plugins,
        StatusBarStyle,
    } from '@capacitor/core';

    const {App, StatusBar} = Plugins;

    export default {
        name: 'app',

        data() {
            return {
                refreshing: false,
                registration: null
            }
        },

        created() {
            if (this.$platform === 'web') {
                this.checkForPWAUpdates();
            }

            if (this.$platform === 'ios') {
                StatusBar.setStyle({
                    style: StatusBarStyle.Light
                });
            }

            if (this.$platform === 'android') {
                App.addListener('backButton', () => {
                    if (this.$route.path === '/dashboard') {
                        App.exitApp();
                    } else {
                        this.$router.back();
                    }
                });
            }
        },

        methods: {
            checkForPWAUpdates() {
                document.addEventListener(
                    'swUpdated', this.showRefreshUI, {once: true}
                );

                navigator.serviceWorker.addEventListener(
                    'controllerchange', () => {
                        if (this.refreshing) return;
                        this.refreshing = true;
                        window.location.reload();
                    }
                );
            },

            showRefreshUI(e) {
                this.registration = e.detail;

                this.$toasted.show("An update is available!", {
                    theme: "toasted-primary",
                    position: "top-center",
                    duration: null,
                    singleton: true,
                    fullWidth: window.innerWidth <= 501,
                    action: [
                        {
                            text: 'Update',
                            onClick: (e, toastObject) => {
                                this.refreshApp();
                                toastObject.goAway(0);
                            }
                        },
                        {
                            text: 'Close',
                            onClick: (e, toastObject) => {
                                toastObject.goAway(0);
                            }
                        },
                    ]
                });
            },

            refreshApp() {
                if (!this.registration || !this.registration.waiting) {
                    return;
                }
                this.registration.waiting.postMessage('skipWaiting');
            }
        }
    }
</script>

<style>
</style>

<style lang="scss">
    $fa-font-path: '~font-awesome/fonts/';
    @import '~font-awesome/scss/font-awesome.scss';
    /* Import Simple Line Icons Set */
    $simple-line-font-path: '~simple-line-icons/fonts/';
    @import '~simple-line-icons/scss/simple-line-icons.scss';
    /* Import Bootstrap Vue Styles */
    @import '~bootstrap-vue/dist/bootstrap-vue.min.css';
    // Import Main styles for this application
    @import 'assets/scss/style';
    @import 'assets/css/animate.min.css';
</style>
