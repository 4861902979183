import Vue from "vue";
import store from '@/store';
import * as firebase from "firebase/app";
import "firebase/messaging";
import router from '@/router'
import {Plugins} from '@capacitor/core';
import {FCM} from "capacitor-fcm";

const {PushNotifications, LocalNotifications} = Plugins;

const PushNotificationsService = {
    initialized: false,

    init() {
        this.initialized = true;
        // console.log('Initializing Push Notifications');

        if (Vue.prototype.$platform !== 'web') {
            this.initForMobile();
        } else {
            this.initForWeb();
        }
    },

    async initForWeb() {
        this.initializeFirebase();

        if ("Notification" in window) {
            await store.dispatch('user/fetchUserSettings');
            let webNotifications = store.getters['user/webNotifications'];

            //In case user set notifications to ask from browser settings
            if (Notification.permission === 'default' && webNotifications) {
                localStorage.setItem('asked_for_web_notifications', "false");
            }

            //In case user has cleared local storage
            if (Notification.permission === 'granted' && !localStorage.getItem('asked_for_web_notifications')) {
                localStorage.setItem('asked_for_web_notifications', "true");
            }

            if (Notification.permission === "granted") {
                this.shownNotificationsIdCleaner();

                this.updateWebPushToken();

                this.messaging.onMessage((payload) => {
                    this.onWebForegroundMessage(payload);
                });

                this.messaging.onTokenRefresh(() => {
                    this.updateWebPushToken();
                });
            } else if (Notification.permission === 'default' && localStorage.getItem('asked_for_web_notifications') !== "true") {
                this.requestPermission();
            }
        } else {
            console.log("This browser does not support web notifications");
        }
    },

    initializeFirebase() {
        try {
            if (!firebase.apps.length) {
                let firebaseConfig = {
                    apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
                    authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
                    databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
                    projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
                    storageBucket: "",
                    messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
                    appId: process.env.VUE_APP_FIREBASE_APP_ID
                };

                firebase.initializeApp(firebaseConfig);
                // Retrieve Firebase Messaging object.
                this.messaging = firebase.messaging();
                this.messaging.usePublicVapidKey(process.env.VUE_APP_FIREBASE_VAPID_KEY);
            }
        } catch (err) {
            console.log(err);
        }
    },

    requestPermission() {
        if (!this.isServiceInitialized()) {
            console.error('Push notifications have not been initialized.');

            return;
        }

        let message = "Web notifications allow you to get notified even <br> when the application is idle or in the background."
            + "<br> Some examples of notifications are device errors, <br> custom notifications when sensor limits are exceeded etc."

        Vue.toasted.show(message, {
            theme: "toasted-primary",
            position: "top-center",
            duration: null,
            singleton: true,
            closeOnSwipe: false,
            fullWidth: window.innerWidth <= 501,
            action: [
                {
                    text: 'Allow',
                    onClick: (e, toastObject) => {
                        Notification.requestPermission().then((permission) => {
                            if (permission === 'granted') {
                                console.log('Notification permission granted.');
                                localStorage.setItem('asked_for_web_notifications', "true");
                                this.updateWebNotifications(1);
                                this.updateWebPushToken();

                                this.messaging.onMessage((payload) => {
                                    this.onWebForegroundMessage(payload);
                                });

                                this.messaging.onTokenRefresh(() => {
                                    this.updateWebPushToken();
                                });
                            } else {
                                console.log('Unable to get permission to notify.');
                                localStorage.setItem('asked_for_web_notifications', "true");
                                this.updateWebNotifications(0);
                            }
                        });

                        toastObject.goAway(0);
                    }
                },
                {
                    text: 'Cancel',
                    onClick: (e, toastObject) => {
                        localStorage.setItem('asked_for_web_notifications', "true");
                        this.updateWebNotifications(0);

                        toastObject.goAway(0);
                    }
                }
            ]
        });
    },

    onWebForegroundMessage(payload) {
        let notificationId = payload.data.notificationId;

        let shownNotifications = localStorage.getItem('shownNotifications');
        if (shownNotifications) {
            shownNotifications = JSON.parse(shownNotifications);

            for (let i = 0; i < shownNotifications.length; i++) {
                if (shownNotifications[i].id == notificationId) {
                    return; //don't handle anything here because push notification was shown from an other tab
                }
            }

            shownNotifications.push({
                id: notificationId,
                createdAt: Date.now()
            });

            localStorage.setItem('shownNotifications', JSON.stringify(shownNotifications));
        } else {
            let arr = [];
            let obj = {
                id: notificationId,
                createdAt: Date.now()
            };
            arr.push(obj);

            localStorage.setItem('shownNotifications', JSON.stringify(arr));
        }

        let options = {
            body: payload.notification.body,
            data: payload.data
        };

        let notification = new Notification(payload.notification.title, options);
        notification.onclick = (event) => {
            let data = event.currentTarget.data;
            this.handleNotificationTapAction(data);
        }
    },

    updateWebPushToken() {
        // Get Instance ID token. Initially this makes a network call, once retrieved subsequent calls to getToken will return from cache.
        this.messaging.getToken().then((currentToken) => {
            if (currentToken) {
                Vue.prototype.$axios.post('/update-push-notifications-token', {
                    uuid: Vue.prototype.$uuid,
                    platform: 'web',
                    token: currentToken
                });
            }
        }).catch((err) => {
            console.log('An error occurred while retrieving token. ', err);
        });
    },

    updateWebNotifications(value) {
        Vue.prototype.$axios({
            method: 'post',
            url: 'update-web-notifications',
            data: {
                notifications: value
            }
        }).then(() => {
            store.commit('user/setWebNotifications', value);
        });
    },

    async initForMobile() {
        await PushNotifications.requestPermission();
        PushNotifications.register();

        // On success, we should be able to receive notifications
        PushNotifications.addListener('registration',
            (token) => {
                console.log('Push registration success, token: ' + token.value);
            }
        );

        // Some issue with our setup and push will not work
        PushNotifications.addListener('registrationError',
            (error) => {
                console.log('Error on registration: ' + JSON.stringify(error));
            }
        );

        PushNotifications.addListener('pushNotificationReceived',
            (notification) => {
                console.log('-------------------------------------');

                try {
                    console.log('Push received: ' + JSON.stringify(notification));

                    let currentUnixTime = Date.now();
                    LocalNotifications.schedule({
                        notifications: [
                            {
                                title: notification.title,
                                body: notification.body,
                                id: currentUnixTime,
                                // icon: 'img/icons/favicon-32x32.png',
                                // icon: 'res://ic_launcher',
                                schedule: {at: new Date(currentUnixTime + 300)},
                                sound: 'default',
                                // attachments: null,
                                // actionTypeId: "",
                                // extra: null
                            }
                        ]
                    });

                    LocalNotifications.addListener("localNotificationActionPerformed", notificationAction => {
                        this.handleNotificationTapAction(notification.data);
                    });
                } catch (err) {
                    console.log(err);
                }

                console.log('-------------------------------------');
            }
        );

        PushNotifications.addListener('pushNotificationActionPerformed',
            (message) => {
                console.log('-------------------------------------');

                try {
                    console.log('Push action performed: ' + JSON.stringify(message));
                    let data = message.notification.data;
                    this.handleNotificationTapAction(data);

                } catch (err) {
                    console.log('Error: ' + err);
                }

                console.log('-------------------------------------');
            }
        );

        const fcm = new FCM();
        fcm.getToken().then(response => {
            Vue.prototype.$axios.post('/update-push-notifications-token', {
                uuid: Vue.prototype.$uuid,
                platform: 'mobile',
                token: response.token
            });
        }).catch(err => console.log(err));
    },

    async close() {
        try {
            this.initialized = false;
            let response = await Vue.prototype.$axios.post('/delete-push-notifications-token', {
                uuid: Vue.prototype.$uuid
            });

            return response;
        } catch (err) {
            console.log('Could not delete push notifications token.')
        }
    },

    handleNotificationTapAction(data) {
        let type = data.type;

        if (type === 'ERRORS_AND_WARNINGS' || type === 'SENSOR_LIMITS' || type === 'DEVICE_OFFLINE') {
            if (router.currentRoute.path !== '/device/' + data.deviceId) {
                router.push('/device/' + data.deviceId);
            }
        }
    },

    /**
     * When a local notification is shown in case where the web app is in foreground that notification's id is stored in localStorage.
     * This is needed because when multiple tab instances are opened multiple local notifications are shown instead of just one.
     * We use this method to create a interval to check if the required time has passed since the id was stored and then clean it up.
     */
    shownNotificationsIdCleaner() {
        setInterval(() => {
            let shownNotifications = localStorage.getItem('shownNotifications');
            if (shownNotifications) {
                let idsToKeep = [];
                shownNotifications = JSON.parse(shownNotifications);
                let now = Date.now();
                let threshold = 5000; //5 seconds

                for (let i = 0; i < shownNotifications.length; i++) {
                    if (now - shownNotifications[i].createdAt < threshold) {
                        idsToKeep.push(shownNotifications[i]);
                    }
                }

                localStorage.setItem('shownNotifications', JSON.stringify(idsToKeep));
            }

        }, 5000);
    },

    isServiceInitialized() {
        return this.initialized;
    }
};

export default PushNotificationsService;