export const ITALIAN_TRANSLATIONS = {
    general: {
        confirm: 'Confermare',
        cancel: 'Anulla',
        mobileUpdateMessage: 'È disponibile un nuovo aggiornamento per l\'app. Ti consigliamo di aggiornare ora affinché l\'app sia stabile e ottieni le funzionalità più recenti.',
        update: 'Aggiorna',
        later: 'Dopo',
        success: 'Successo'
    },
    login: {
        signInDesc: 'Accedi al tuo account',
        invalidCredentials: 'Le credenziali fornite non sono valide.',
        notVerified: 'L\'account utente non è stato verificato. Controlla la tua e-mail per un\'e-mail di verifica.',
        errorOccurred: 'Si è verificato un errore, impossibile connettersi al server',
        verificationEmailSent: 'Un\'email di verifica è stata rinviata al tuo indirizzo email.',
        register: 'Registrare',
        registerDesc: 'Registrati ora per iniziare subito a connettere il tuo dispositivo al nostro Things Network',
        registerNow: 'Registrati Ora!',
        registerModalDesc: 'Ti preghiamo di compilare correttamente i seguenti campi per registrare il tuo account',
        enterEmail: 'Inserisci l\'email',
        emailNotFormattedProperly: 'La tua email non è formattata correttamente.',
        emailEmpty: 'L\'email non deve essere vuota',
        emailFormattedProperly: 'La tua email è formattata correttamente.',
        emailCouldNotBeSent: 'Non è stato possibile inviare l\'e-mail',
        enterPassword: 'Inserire la password',
        atLeast6Chars: 'Inserisci almeno 6 caratteri',
        passwordMustNotBeEmpty: 'La password non deve essere vuota',
        confirmationPasswordMustNotBeEmpty: 'La password di conferma non deve essere vuota',
        correct: 'Corretto',
        forgotPassword: 'Ha dimenticato la password?',
        confirmPassword: 'Confermare la password',
        resetPasswordTitle: 'Resetta la password',
        resetPasswordDesc: 'Inserisci il tuo indirizzo email',
    },
    sidebar: {
        navigation: 'NAVIGAZIONE'
    },
    devices: {
        header: 'Dispositivi',
        noDeviceTitle: 'Nessun dispositivo aggiunto',
        noDeviceDesc: 'Non hai aggiunto alcun dispositivo. Fare clic sul pulsante rosso flottante nell\'angolo in basso a destra dello schermo per aggiungere un nuovo dispositivo.',
        noDataForDisplay: 'Non ci sono dati disponibili per la visualizzazione.',
        linkToEdit: 'Collegare un dispositivo per poterlo modificare.',
        linkSuccess: 'Il tuo dispositivo è stato collegato correttamente',
        linkFailed: 'Impossibile collegare il dispositivo.',
        timeout: 'Timeout, il dispositivo non ha risposto.',
        unlinked: 'Scollegato',
        empty: 'Vuoto',
        name: 'Nome',
        desc: 'Descrizione',
        status: 'Stato',
        actions: 'Azioni',
        addDeviceTitle: 'Aggiungi dispositivo',
        addDeviceDesc: 'Insericsi il nome del dispositivo',
        nameEmpty: 'Il nome del modello non deve essere vuoto',
        nameMoreThan4Chars: 'Inserisci almeno 4 caratteri',
        nameMoreThan20Chars: 'Il nome del modello non deve contenere più di 20 caratteri',
        ready: 'Pronto',
        deleteDeviceTitle: 'Elimina dispositivo',
        deleteDeviceDesc: 'Sei sicuro di voler eliminare questo dispositivo?',
        unlinkDeviceTitle: 'Scollega dispositivo',
        unlinkDeviceDesc: 'Sei sicuro di voler scollegare questo dispositivo?',
        linkingMethod: 'Scegli il metodo di collegamento',
        scanQRCode: 'Scansiona il codice QR sullo schermo del tuo dispositivo',
        linkDeviceTitle: 'Collega il dispositivo',
        linkDeviceDesc: 'Inserisci il codice di accesso\n',
        enterPasscode: 'Inserisci il codice di accesso',
        passcode6Digits: 'Il codice di accesso deve contenere 6 cifre',
        passcodeNotEmpty: 'Il passcode non deve essere vuoto'
    },
    templates: {
        header: 'Modelli',
        parameter: 'Parametro',
        value: 'Valore',
        noTemplates: 'Non hai salvato alcun modello.',
        renameTemplate: 'Rinomina modello',
        enterTemplateName: 'Inserisci il nome del modello',
        nameEmpty: 'Il nome del modello non deve essere vuoto',
        nameMoreThan4Chars: 'Inserisci almeno 4 caratteri',
        nameMoreThan20Chars: 'Il nome del modello non deve contenere più di 20 caratteri',
        ready: 'Pronto',
        renameSuccessful: 'Il modello è stato rinominato correttamente.',
        templateRenameError: 'Si è verificato un errore, impossibile rinominare il modello.',
        deleteTemplate: 'Elimina modello',
        deleteConfirmationMessage: '  Sei sicuro di voler eliminare questo modello?',
        templateDeleteError: 'Si è verificato un errore, impossibile eliminare il modello.'
    },
    documents: {
        header: 'Documenti',
        tdcManual: 'Manuale TDC',
        manualReleaseDatePleion: '23 Giugno 2020',
        manualDescriptionPleion: 'Il regolatore di differenza di temperatura TDC V1 facilita l\'uso efficiente e il controllo delle funzioni del sistema solare o di riscaldamento.' +
            'Il dispositivo è impressionante soprattutto per la sua funzionalità e il funzionamento semplice, quasi autoesplicativo.',
        createYourFirstDevice: 'Crea il tuo primo dispositivo',
        createYourFirstDeviceDesc: 'Di seguito è riportato un breve video che descrive i passaggi necessari per la creazione di un dispositivo virtuale. ' +
            'Questa è la prima cosa che devi fare prima di provare a collegare il tuo dispositivo al tuo account online.',
        connectDeviceToWifi: 'Connessione del dispositivo al Wi-Fi',
        connectDeviceToWifiDesc: 'Ora che abbiamo aggiunto un dispositivo virtuale, dobbiamo collegare il nostro dispositivo fisico a un punto di accesso Wi-Fi prima di poterlo collegare al nostro account online.',
        linkDeviceToAccount: 'Collegamento del dispositivo al tuo account online',
        linkDeviceToAccountDesc: 'Dopo aver collegato il dispositivo a un punto di accesso Wi-Fi, la prossima cosa da fare è collegarlo al tuo account online. ' +
            'Guarda il video qui sotto per maggiori dettagli.',
        setupVariantAndModules: 'Imposta la variante del dispositivo e configura i moduli',
        setupVariantAndModulesDesc: 'Ora che il tuo dispositivo è collegato al tuo account, puoi impostare la sua modalità di lavoro e personalizzarla per soddisfare le esigenze dei tuoi sistemi.'
    },
    settings: {
        header: 'Impostazioni',
        general: 'Generale',
        profile: 'Profilo',
        myEmail: 'Mio Email',
        update: 'Aggiorna',
        updatePassword: 'Aggiornare password',
        enterOldPassword: 'Inserisci la vecchia password',
        enterNewPassword: 'Inserire una nuova password',
        reenterNewPassword: 'Reinserire una nuova password',
        cancel: 'Anulla',
        devicesAppearance: 'Aspetto dei dispositivi',
        grid: 'Griglia',
        list: 'Lista',
        language: 'Lingua',
        english: 'Inglese',
        italian: 'Italiano',
        notifications: 'Notifiche',
        webNotifications: 'Notifiche Web',
        webNotificationsDesc: 'Le notifiche Web consentono di ricevere notifiche anche quando l\'applicazione è inattiva o in background.<br>' +
            'Alcuni esempi di notifiche sono errori del dispositivo, notifiche personalizzate in caso di superamento dei limiti del sensore, ecc.',
        webNotificationsDisabled: 'Hai disabilitato le notifiche web per questo sito, per abilitarle vai alle impostazioni del tuo browser e impostale per consentire.',
        pushNotifications: 'Notifiche Push',
        pushNotificationsDesc: 'Le notifiche push consentono di ricevere notifiche anche quando l\'applicazione è inattiva o in background. <br>\n' +
            'Alcuni esempi di notifiche sono errori del dispositivo, notifiche personalizzate in caso di superamento dei limiti del sensore, ecc.',
        pushNotificationsDisabled: ' Hai disabilitato le notifiche push per questa app, per abilitarle vai alle impostazioni del tuo telefono.',
        emailNotifications: 'Notifiche Email',
        emailNotificationsDesc: 'Le notifiche e-mail ti consentono di ricevere notifiche via e-mail su vari errori sul tuo dispositivo.',
        maintenanceEmail: 'Notifiche di manutenzione',
        maintenanceEmailDesc: 'Abilitare se si desidera essere avvisati via e-mail quando si verificherà una manutenzione.',
        other: 'Altro',
        logout: 'Esci'
    },
    help: {
        header: 'Aiuto',
        support: 'Supporto',
        supportDesc1: 'In caso di difficoltà tecniche, non esitate a contattarci tramite la nostra e-mail',
        supportDesc2: 'Il nostro staff sarà pronto a rispondere a qualsiasi tua domanda.'
    },
    deviceInfo: {
        deviceHistory: 'Cronologia dispositivo',
        chartLoadingData: 'Caricamento dati dal server...',
        relayActiveTime: 'Tempo attivo relè',
        energyProduction: 'Produzione di energia',
        variant: 'Variante',
        module: 'Modulo',
        schedule: 'Orari',
        heat: 'Caldo',
        cool: 'Freddo',
        actual: 'Att.',
        relay: 'Rele',
        heatPump: 'Pompa di calore',
        currentPower: 'Potenza attuale',
        today: 'Oggi',
        thisMonth: 'Questo Mese',
        thisYear: 'Questo Anno'
    },
    deviceEdit: {
        general: 'Generale',
        deviceName: 'Nome del dispositivo',
        deviceDesc: 'Descrizione del dispositivo',
        firmwareVersion: 'Versione firmware',
        muteNotifications: 'Disattiva le notifiche',
        dontMute: 'Non silenziare',
        oneHour: '1 ora',
        sixHours: '6 ore',
        twelveHours: '12 ore',
        oneDay: '1 giorno',
        muteIndefinitely: 'Disattiva a tempo indeterminato',
        noNotificationMethodsPart1: 'Non hai abilitato alcun metodo di notifica. ',
        noNotificationMethodsPart2: 'Vai alle impostazioni dell\'account e abilita un metodo di notifica se vuoi iniziare a ricevere le notifiche.',
        sensorNotificationLimits: 'Limiti di notifica del sensore',
        sensorNotificationLimitsDesc: 'Ricevi una notifica quando la temperatura del sensore scende al di sotto o al di sopra dei limiti impostati.',
        sensorsAndRelaysNamings: 'Nomi dei sensori e dei relè',
        sensorsAndRelaysNamingsPart1: 'Il nome dovrebbe essere tra',
        sensorsAndRelaysNamingsPart2: 'e',
        sensorsAndRelaysNamingsPart3: 'lettere',
        deviceSharing: 'Condivisione del dispositivo',
        enterEmail: 'Inserisci l\'email',
        share: 'Condividere',
        unshare: 'Rimuovere',
        viewer: 'Spettatore',
        maintainer: 'Manutentore',
        accountNotFound: 'Non è stato possibile trovare un account registrato con l\'indirizzo e-mail fornito.',
        alreadyShared: 'Hai condiviso questo dispositivo con questo indirizzo email.',
        maximumSharingNumber: 'Hai raggiunto il numero massimo di condivisioni.',
        privilegeUpdateSuccess: 'Privilegio aggiornato con successo.',
        privilegeUpdateFail: 'Impossibile aggiornare il privilegio dell\'utente.',
        solarSettings: 'Impostazioni Solari',
        pumps: 'Pompe',
        modules: 'Moduli',
        protections: 'Protezioni',
        system: 'Sistema',
        collector: 'Collettore',
        recooling: 'Raffreddamento',
        frost: 'Antigelo',
        seizing: 'Antibloccaggio',
        storageAlarm: 'Alarme Serbatoio',
        manualOperations: 'Operazioni Manuali',
        setup: 'Impostazioni iniziali',
        sensorConfig: 'Configurazione dei sensori',
        datetime: 'Data e ora',
        holiday: 'Vacanza',
        manageTemplates: 'Gestisci modelli',
        manageTemplatesDescV0: 'Salvare gli attuali parametri configurati del dispositivo fisico come modello che può essere visualizzato in seguito.',
        manageTemplatesDescV1: 'La gestione dei modelli consente di salvare gli attuali parametri configurati del dispositivo fisico come modello che ' +
            'può essere visualizzato in un secondo momento o caricare un modello esistente sul dispositivo.',
        save: 'Salva',
        load: 'Carica',
        saveTemplate: 'Salva modello',
        enterTemplateName: 'Inserisci il nome del modello',
        templateSavedSuccessfully: 'Il modello è stato salvato correttamente.',
        maxLimit: 'Limite massimo raggiunto',
        noMoreThan20: 'Non è possibile salvare più di 20 modelli.',
        fail: 'Fallire',
        couldNotBeSaved: 'Non è stato possibile salvare il modello.',
        templateNameNoMoreThan20Chars: 'Il nome del modello non deve contenere più di 20 caratteri',
        atLeast4Chars: 'Inserisci almeno 4 caratteri',
        notEmpty: 'Il nome del modello non deve essere vuoto',
        ready: 'Pronto',
        loadTemplate: 'Carica modello',
        selectTemplateToLoad: 'Seleziona un modello che desideri caricare sul tuo dispositivo.',
        view: 'Visualizza',
        loading: 'Caricamento in corso...',
        parametersLoadedSuccessfully: 'Tutti i parametri sono stati caricati correttamente.',
        warning: 'Avvertimento',
        followingParametersCouldNotBeSet: 'Non è stato possibile impostare i seguenti parametri:',
        notCompatible: 'Non compatibile',
        selectAnOption: 'Seleziona un\'opzione',
        resetEnergyData: 'Ripristina dati energia',
        deleteDataProducedByDevice: 'Elimina tutti i dati sull\'energia prodotta da questo dispositivo.',
        reset: 'Ripristina',
        deleteEnergyData: 'Elimina i dati energetici',
        doYouWantToDeleteData: 'Sei sicuro di voler cancellare i dati energetici di questo dispositivo?',
        daily: 'Quotidiano',
        weekly: 'Settimanalmente',
        schedule: 'Programma',
        forced: 'Forzato',
        inverted: 'Invertito',
        normal: 'Normale',
        disabled: 'Disabilitato',
        thermostat: 'Termostato',
        recycle: 'Riciclo',
        fireplace: 'Termocamino',
        heatExchange: 'Scambiatore',
        parallelRelay: 'Relè parallelo',
        flowSwitch: 'FlussoStato',
        alternate: 'Alternata',
        absolute: 'Assoluta',
        yes: 'Si',
        heat: 'Caldo',
        cool: 'Fredo',
        changeVariant: 'Modifica variante',
        changeVariantDescPart1: 'Sei sicuro di voler cambiare la variante del dispositivo?',
        changeVariantDescPart2: 'Ciò potrebbe farti perdere alcune delle configurazioni che hai impostato sul dispositivo.'
    },
    notificationsHeader: {
        offlineMsg: 'Sei offline, controlla la tua connessione Internet.',
        sampleData: 'Dati di esempio',
        sampleDataTooltip: 'Poiché non hai collegato un dispositivo fisico a questo dispositivo virtuale, i dati mostrati sono solo un esempio.',
        deviceNotLinked: 'Il dispositivo non è collegato',
        deviceNotLinkedTooltip: 'Poiché il dispositivo non è collegato, la maggior parte dei dati è l\'ultima istantanea dallo stato del dispositivo e di sola lettura.',
        deviceOffline: 'Il dispositivo non è in linea',
        manualMode: 'Il dispositivo è in modalità manuale',
        sensorDefective: 'Sensore difettoso',
        systemProtection: 'Protezione del sistema',
        collectorProtection: 'Protezione del collettore',
        recoolingProtection: 'Protezione da raffreddamento',
        frostProtection: 'Protezione antigelo',
        seizeProtection: 'Protezione bloccaggio',
        storageAlarm: 'Alarme di accumolo'
    },
    resetPassword: {
        resetPassword: 'Resetta la password',
        resetPasswordDesc: 'Inserisci una nuova password per il tuo account',
        newPassword: 'Nuova password',
        repeatPassword: 'Ripeti la password',
        updatePassword: 'Aggiorna password',
        resetSuccess: 'La tua password è stata aggiornata con successo.',
        errorOccurred: 'Si è verificato un errore.'
    }
};