import Vue from "vue";
import i18n from "../../locales/i18n";

const state = {
    email: "",
    isDemo: 0,
    isAdmin: 0,
    receiveMaintenanceEmail: "",
    webNotifications: "",
    mobileNotifications: "",
    emailNotifications: "",
    devicesView: "grid",
    language: "en"
};

const getters = {
    email: state => {
        return state.email;
    },

    isDemo: state => {
        return state.isDemo;
    },

    isAdmin: state => {
        return state.isAdmin;
    },

    receiveMaintenanceEmail: state => {
        return state.receiveMaintenanceEmail;
    },

    webNotifications: state => {
        return state.webNotifications;
    },

    mobileNotifications: state => {
        return state.mobileNotifications;
    },

    emailNotifications: state => {
        return state.emailNotifications;
    },

    devicesView: state => {
        return state.devicesView;
    },

    language: state => {
        return state.language
    }
};

const mutations = {
    setEmail(state, email) {
        state.email = email;
    },

    setIsDemo(state, isDemo) {
        state.isDemo = isDemo;
    },

    setIsAdmin(state, isAdmin) {
        state.isAdmin = isAdmin;
    },

    setReceiveMaintenanceEmail(state, value) {
        state.receiveMaintenanceEmail = value;
    },

    setWebNotifications(state, value) {
        state.webNotifications = value;
    },

    setMobileNotifications(state, value) {
        state.mobileNotifications = value;
    },

    setEmailNotifications(state, value) {
        state.emailNotifications = value;
    },

    setDevicesView(state, value) {
        state.devicesView = value;
    },

    setLanguage(state, value) {
        state.language = value;
        i18n.locale = value;
    }
};

const actions = {
    async fetchUserSettings({commit}) {
        let response = await Vue.prototype.$axios.get('/user-settings');

        commit('setEmail', response.data.email);
        commit('setIsDemo', response.data.isDemo);
        commit('setIsAdmin', response.data.isAdmin);
        commit('setReceiveMaintenanceEmail', response.data.receiveMaintenanceEmail);
        commit('setWebNotifications', response.data.webNotifications);
        commit('setMobileNotifications', response.data.mobileNotifications);
        commit('setEmailNotifications', response.data.emailNotifications);
        commit('setDevicesView', response.data.devicesView);
        commit('setLanguage', response.data.language);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};