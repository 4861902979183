import Vue from 'vue';

const state = {
    deviceList: {}
};

const actions = {
    async fetchDeviceList({commit}) {
        let response = await Vue.prototype.$axios.get('/device-list');

        return response;
    },

    async fetchInfoViewData({dispatch, commit, getters}, deviceId) {
        commit('addDeviceToListIfNotFound', deviceId);

        let response = await Vue.prototype.$axios.get('/view-device', {
            params: {
                id: deviceId
            }
        });

        commit('setDeviceParameters', {parameters: response.data.parameters, deviceId: deviceId});
        commit('setDeviceErrors', {deviceErrors: response.data.errors, deviceId: deviceId});
        commit('setDeviceName', {name: response.data.name, deviceId: deviceId});
        commit('setDeviceDesc', {desc: response.data.desc, deviceId: deviceId});
        commit('setMacAddress', {macAddress: response.data.macAddress, deviceId: deviceId});
        commit('setUiVersion', {uiVersion: response.data.uiVersion, deviceId: deviceId});
        commit('setUnlinked', {unlinked: response.data.unlinked, deviceId: deviceId});
        commit('setDeviceVariant', {variant: response.data.variant, deviceId: deviceId});
        commit('setIsOnline', {isOnline: response.data.isOnline, deviceId: deviceId});
        commit('setUserPrivilege', {userPrivilege: response.data.userPrivilege, deviceId: deviceId});

        await dispatch('fetchSensorAndRelayData', deviceId);
    },

    async fetchEditViewData({commit, getters}, deviceId) {
        commit('addDeviceToListIfNotFound', deviceId);

        let rsp1 = await Vue.prototype.$axios.get('/edit-device', {
            params: {
                id: deviceId
            }
        });

        let rsp2 = await Vue.prototype.$axios.get('/get-general-settings', {
            params: {
                id: deviceId
            }
        });

        commit('setDeviceParameters', {parameters: rsp1.data.parameters, deviceId: deviceId});
        commit('setDeviceErrors', {deviceErrors: rsp1.data.errors, deviceId: deviceId});
        commit('setDeviceActiveSensors', {activeSensors: rsp1.data.activeSensors, deviceId: deviceId});
        commit('setDeviceName', {name: rsp1.data.name, deviceId: deviceId});
        commit('setDeviceDesc', {desc: rsp1.data.desc, deviceId: deviceId});
        commit('setMacAddress', {macAddress: rsp1.data.macAddress, deviceId: deviceId});
        commit('setUiVersion', {uiVersion: rsp1.data.uiVersion, deviceId: deviceId});
        commit('setUnlinked', {unlinked: rsp1.data.unlinked, deviceId: deviceId});
        commit('setDeviceVariant', {variant: rsp1.data.variant, deviceId: deviceId});
        commit('setIsOnline', {isOnline: rsp1.data.isOnline, deviceId: deviceId});
        commit('setUserPrivilege', {userPrivilege: rsp1.data.userPrivilege, deviceId: deviceId});
        commit('setGeneralSettings', {
            generalSettings: {
                'firmwareVersion': rsp2.data.firmwareVersion,
                'muteNotifications': rsp2.data.muteOptionId,
                'muteUntil': rsp2.data.muteUntil,
                'sharedWith': rsp2.data.sharedWith,
                's1Min': rsp2.data.s1_min,
                's1Max': rsp2.data.s1_max,
                's2Min': rsp2.data.s2_min,
                's2Max': rsp2.data.s2_max,
                's3Min': rsp2.data.s3_min,
                's3Max': rsp2.data.s3_max,
                's4Min': rsp2.data.s4_min,
                's4Max': rsp2.data.s4_max
            },
            deviceId: deviceId
        });

        let fields = {
            's1Name': rsp2.data.s1_name,
            's2Name': rsp2.data.s2_name,
            's3Name': rsp2.data.s3_name,
            's4Name': rsp2.data.s4_name,
            'r1Name': rsp2.data.r1_name,
            'r2Name': rsp2.data.r2_name,
            'r3Name': rsp2.data.r3_name
        };
        commit('setDeviceSensorsRelaysNamings', {deviceId: deviceId, fields: fields});
    },

    async fetchSensorAndRelayData({commit, state}, deviceId) {
        let response = await Vue.prototype.$axios.get('/get-sensors-and-relays-data', {
            params: {
                id: deviceId
            }
        });

        commit('setDeviceData', {
            deviceData: {
                sens_1: response.data.sens_1,
                sens_2: response.data.sens_2,
                sens_3: response.data.sens_3,
                sens_4: response.data.sens_4,
                r_1: response.data.r_1,
                r_2: response.data.r_2,
                r_3: response.data.r_3,
                r_4: response.data.r_4,
                analogue_1: response.data.analogue_1,
                analogue_1_ref: response.data.analogue_1_ref
            },
            deviceId: deviceId
        });

        let fields = {
            's1Name': response.data.s1_name,
            's2Name': response.data.s2_name,
            's3Name': response.data.s3_name,
            's4Name': response.data.s4_name,
            'r1Name': response.data.r1_name,
            'r2Name': response.data.r2_name,
            'r3Name': response.data.r3_name
        };
        commit('setDeviceSensorsRelaysNamings', {deviceId: deviceId, fields: fields});

        return response;
    },

    async fetchChartData({commit, state}, deviceId) {
        let response = await Vue.prototype.$axios.get('/get-first-time-chart-data', {
            params: {
                id: deviceId
            }
        });

        return response;
    },

    async onDeviceDataUpdate({commit, state}, data) {
        commit('addDeviceToListIfNotFound', data.id_device);
        commit('setDeviceData', {deviceData: data, deviceId: data.id_device});
        commit('setDeviceActiveSensors', {
            deviceId: data.id_device,
            activeSensors: [data.sens_1 !== null, data.sens_2 !== null, data.sens_3 !== null, data.sens_4 !== null]
        });
    },

    async onDeviceErrorsUpdate({commit, state}, data) {
        commit('addDeviceToListIfNotFound', data.id_device);
        commit('setDeviceErrors', {deviceErrors: data, deviceId: data.id_device});
    },

    async onDeviceParametersUpdate({commit, state}, data) {
        commit('addDeviceToListIfNotFound', data.id_device);
        commit('setDeviceParameters', {parameters: data, deviceId: data.id_device});
    }
};

const getters = {
    deviceList: state => {
        return state.deviceList;
    },

    device: state => deviceId => {
        return state.deviceList[deviceId];
    },

    deviceName: state => deviceId => {
        return state.deviceList[deviceId].name;
    },

    deviceDesc: state => deviceId => {
        return state.deviceList[deviceId].desc;
    },

    macAddress: state => deviceId => {
        return state.deviceList[deviceId].macAddress;
    },

    deviceData: state => deviceId => {
        return state.deviceList[deviceId].data;
    },

    deviceErrors: state => deviceId => {
        return state.deviceList[deviceId].errors;
    },

    parameters: state => deviceId => {
        return state.deviceList[deviceId].parameters;
    },

    activeSensors: state => deviceId => {
        return state.deviceList[deviceId].activeSensors;
    },

    userPrivilege: state => deviceId => {
        return state.deviceList[deviceId].userPrivilege;
    },

    uiVersion: state => deviceId => {
        return state.deviceList[deviceId].uiVersion;
    },

    unlinked: state => deviceId => {
        return state.deviceList[deviceId].unlinked;
    },

    deviceVariant: state => deviceId => {
        return state.deviceList[deviceId].variant;
    },

    isOnline: state => deviceId => {
        return state.deviceList[deviceId].online;
    },

    muteNotifications: state => deviceId => {
        return state.deviceList[deviceId].generalSettings.muteNotifications;
    },

    muteUntil: state => deviceId => {
        return state.deviceList[deviceId].generalSettings.muteUntil;
    },

    firmwareVersion: state => data => {
        return state.deviceList[data.deviceId].generalSettings.firmwareVersion;
    },

    sensorLimit: state => data => {
        return state.deviceList[data.deviceId].generalSettings['s' + data.sensorId + data.sensorType];
    },

    sensorName: state => data => {
        return state.deviceList[data.deviceId]['sensorsRelaysNamings']['s' + data.sensorId + "Name"];
    },

    relayName: state => data => {
        return state.deviceList[data.deviceId]['sensorsRelaysNamings']['r' + data.relayId + "Name"];
    },

    sharedWith: state => deviceId => {
        return state.deviceList[deviceId].generalSettings.sharedWith;
    },

    showManualOperationKey: (state) => (data) => {
        if (data.key === 'p_72') {
            return true;
        } else if (data.key === 'p_73' || data.key === 'p_74' || data.key === 'p_75') {
            let parameters = state.deviceList[data.deviceId].parameters;

            return parseInt(parameters['p_72'].value) === 1;
        }

    },
};

const mutations = {
    emptyDeviceList(state) {
        Vue.set(state, 'deviceList', {});
    },

    addDevice(state, device) {
        Vue.set(state.deviceList, device.id, device);
    },

    addDeviceToListIfNotFound(state, deviceId) {
        if (!state.deviceList[deviceId]) {
            Vue.set(state.deviceList, deviceId, {id: deviceId, device: {id: deviceId}});
        }
    },

    linkDevice(state, data) {
        let macAddress = data.macAddress;
        let id = data.id;

        Vue.set(state.deviceList[id], 'mac_address', macAddress);
        Vue.set(state.deviceList[id], 'unlinked', false);
    },

    unlinkDevice(state, id) {
        Vue.set(state.deviceList[id], 'mac_address', null);
        Vue.set(state.deviceList[id], 'unlinked', true);
    },

    deleteDevice(state, id) {
        Vue.delete(state.deviceList, id);
    },

    setDeviceName(state, data) {
        Vue.set(state.deviceList[data.deviceId], 'name', data.name);
    },

    setDeviceDesc(state, data) {
        Vue.set(state.deviceList[data.deviceId], 'desc', data.desc);
    },

    setMacAddress(state, data) {
        Vue.set(state.deviceList[data.deviceId], 'macAddress', data.macAddress);
    },

    setDeviceData(state, data) {
        Vue.set(state.deviceList[data.deviceId], 'data', data.deviceData);
    },

    setDeviceErrors(state, data) {
        Vue.set(state.deviceList[data.deviceId], 'errors', data.deviceErrors);
    },

    setDeviceParameters(state, data) {
        for (let key in data.parameters) {
            if (key === 'id_device') continue;
            data.parameters[key].id = key.split('p_')[1];
        }

        Vue.set(state.deviceList[data.deviceId], 'parameters', data.parameters);
    },

    setDeviceParameterValue(state, data) {
        Vue.set(state.deviceList[data.deviceId].parameters[data.paramKey], 'value', data.paramValue);
    },

    setDeviceActiveSensors(state, data) {
        Vue.set(state.deviceList[data.deviceId], 'activeSensors', data.activeSensors);
    },

    setGeneralSettings(state, data) {
        Vue.set(state.deviceList[data.deviceId], 'generalSettings', data.generalSettings);
    },

    setMuteNotifications(state, data) {
        Vue.set(state.deviceList[data.deviceId].generalSettings, 'muteNotifications', data.muteNotifications);
    },

    setMuteUntil(state, data) {
        Vue.set(state.deviceList[data.deviceId].generalSettings, 'muteUntil', data.muteUntil);
    },

    addToSharedWith(state, data) {
        Vue.set(state.deviceList[data.deviceId].generalSettings['sharedWith'], state.deviceList[data.deviceId].generalSettings['sharedWith'].length, data.newUser);
    },

    removeFromSharedWith(state, data) {
        let sharedWith = state.deviceList[data.deviceId].generalSettings['sharedWith'];
        for (let i = sharedWith.length - 1; i >= 0; i -= 1) {
            if (sharedWith[i].email === data.email) {
                sharedWith.splice(i, 1);
            }
        }
    },

    setDeviceSensorsRelaysNamings(state, data) {
        Vue.set(state.deviceList[data.deviceId], 'sensorsRelaysNamings', data.fields);
    },

    setSensorLimit(state, data) {
        Vue.set(state.deviceList[data.deviceId].generalSettings, data.field, data.sensorValue);
    },

    setSensorName(state, data) {
        Vue.set(state.deviceList[data.deviceId][['sensorsRelaysNamings']], "s" + data.sensorId + "Name", data.sensorName);
    },

    setRelayName(state, data) {
        Vue.set(state.deviceList[data.deviceId][['sensorsRelaysNamings']], "r" + data.relayId + "Name", data.relayName);
    },

    setUserPrivilege(state, data) {
        Vue.set(state.deviceList[data.deviceId], 'userPrivilege', data.userPrivilege);
    },

    setUiVersion(state, data) {
        Vue.set(state.deviceList[data.deviceId], 'uiVersion', data.uiVersion);
    },

    setUnlinked(state, data) {
        Vue.set(state.deviceList[data.deviceId], 'unlinked', data.unlinked);
    },

    setDeviceVariant(state, data) {
        Vue.set(state.deviceList[data.deviceId], 'variant', data.variant);
    },

    setIsOnline(state, data) {
        Vue.set(state.deviceList[data.deviceId], 'online', data.isOnline);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};