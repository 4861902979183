import Vue from 'vue';
import VueI18n from 'vue-i18n';
import {ENGLISH_TRANSLATIONS} from "./en";
import {ITALIAN_TRANSLATIONS} from "./it";

Vue.use(VueI18n);

const TRANSLATIONS = {
    en: ENGLISH_TRANSLATIONS,
    it: ITALIAN_TRANSLATIONS
}
const i18n = new VueI18n({
    locale: 'en',
    messages: TRANSLATIONS,
})

export default i18n;