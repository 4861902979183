export const ENGLISH_TRANSLATIONS = {
    general: {
        confirm: 'Confirm',
        cancel: 'Cancel',
        mobileUpdateMessage: 'A new update for your app is available. We highly recommend you to update now for your app to be stable and get the latest features.',
        update: 'Update',
        later: 'Later',
        success: 'Success'
    },
    login: {
        signInDesc: 'Sign In to your account',
        invalidCredentials: 'The credentials you provided are not valid.',
        notVerified: 'User account has not been verified. Please check your email for a verification email.',
        errorOccurred: 'An error occurred, could not connect to server',
        verificationEmailSent: 'A verification email has been resent to your email address.',
        register: 'Register',
        registerDesc: 'Register now to so you can immediately start to connect your device to our Things Network',
        registerNow: 'Register Now!',
        registerModalDesc: 'Please fill the following fields properly to register your account',
        enterEmail: 'Enter email',
        emailNotFormattedProperly: 'Your email is not formatted properly.',
        emailEmpty: 'Email must not be empty.',
        emailFormattedProperly: 'Your email is formatted properly.',
        emailCouldNotBeSent: 'The email could not be sent',
        enterPassword: 'Enter password',
        atLeast6Chars: 'Enter at least 6 characters',
        passwordMustNotBeEmpty: 'Password must not be empty',
        confirmationPasswordMustNotBeEmpty: 'Confirmation password must not be empty',
        correct: 'Correct',
        forgotPassword: 'Forgot password?',
        confirmPassword: 'Confirm password',
        resetPasswordTitle: 'Reset Password',
        resetPasswordDesc: 'Please enter your email address',
    },
    sidebar: {
        navigation: 'Navigation'
    },
    devices: {
        header: 'Devices',
        noDeviceTitle: 'No devices added',
        noDeviceDesc: 'You have not added any devices. Please click the red floating button on the right bottom corner of the screen to add a new device.',
        noDataForDisplay: 'There is no data available for display.',
        linkToEdit: 'Link a device to be able to edit it.',
        linkSuccess: 'Your device was linked successfully',
        linkFailed: 'Could not link device.',
        timeout: 'Timeout, the device did not respond.',
        unlinked: 'Unlinked',
        empty: 'Empty',
        name: 'Name',
        desc: 'Description',
        status: 'Status',
        actions: 'Actions',
        addDeviceTitle: 'Add device',
        addDeviceDesc: 'Enter device name',
        nameEmpty: 'Template name must not be empty',
        nameMoreThan4Chars: 'Enter at least 4 characters',
        nameMoreThan20Chars: 'Template name must be no more than 20 characters',
        ready: 'Ready',
        deleteDeviceTitle: 'Delete Device',
        deleteDeviceDesc: 'Are you sure you want to delete this device?',
        unlinkDeviceTitle: 'Unlink Device',
        unlinkDeviceDesc: 'Are you sure you want to unlink this device?',
        linkingMethod: 'Choose linking method',
        scanQRCode: 'Scan QR code on your device\'s screen',
        linkDeviceTitle: 'Link device',
        linkDeviceDesc: 'Enter passcode',
        enterPasscode: 'Enter passcode',
        passcode6Digits: 'Passcode must have 6 digits',
        passcodeNotEmpty: 'Passcode must not be empty'
    },
    templates: {
        header: 'Template',
        parameter: 'Parameter',
        value: 'Value',
        noTemplates: 'You haven\'t saved any templates.',
        renameTemplate: 'Rename template',
        enterTemplateName: 'Enter template name',
        nameEmpty: 'Template name must not be empty',
        nameMoreThan4Chars: 'Enter at least 4 characters',
        nameMoreThan20Chars: 'Template name must be no more than 20 characters',
        ready: 'Ready',
        renameSuccessful: 'Template was renamed successfully.',
        templateRenameError: 'An error occurred, could not rename template.',
        deleteTemplate: 'Delete template',
        deleteConfirmationMessage: 'Are you sure that you want to delete this template?',
        templateDeleteError: 'An error occurred, could not delete template.'
    },
    documents: {
        header: 'Documents',
        tdcManual: 'TDC Manual',
        manualReleaseDatePleion: '23 June 2020',
        manualDescriptionPleion: 'The Temperature Difference Controller TDC V1 facilitates efficient use and function control of your solar or heating system. ' +
            'The device is impressive most of all for its functionality and simple, almost self-explanatory operation.',
        createYourFirstDevice: 'Create your first device',
        createYourFirstDeviceDesc: 'Below is a short video describing the necessary steps for creating a virtual device. ' +
            'This is the first thing you must do before trying to link your device to your online account.',
        connectDeviceToWifi: 'Connecting your device to Wi-Fi',
        connectDeviceToWifiDesc: 'Now that we have added a virtual device we need to connect our physical device to a Wi-Fi access point before being able to link it to our online account.',
        linkDeviceToAccount: 'Linking your device to your online account',
        linkDeviceToAccountDesc: 'After having connected your device to a Wi-Fi access point the next thing to do is linking it to your online account. ' +
            'Watch the video below for more details.',
        setupVariantAndModules: 'Set up device variant and configure modules',
        setupVariantAndModulesDesc: 'Now that your device is linked to your account you can setup its working mode and customize it to fulfill your systems\' needs.'
    },
    settings: {
        header: 'Settings',
        general: 'General',
        profile: 'Profile',
        myEmail: 'My Email',
        update: 'Update',
        updatePassword: 'Update password',
        enterOldPassword: 'Enter old password',
        enterNewPassword: 'Enter new password',
        reenterNewPassword: 'Reenter new password',
        cancel: 'Cancel',
        devicesAppearance: 'Devices appearance',
        grid: 'Grid',
        list: 'List',
        language: 'Language',
        english: 'English',
        italian: 'Italian',
        notifications: 'Notifications',
        webNotifications: 'Web Notifications',
        webNotificationsDesc: 'Web notifications allow you to get notified even when the application is idle or in the background.<br>' +
            'Some examples of notifications are device errors, custom notifications when sensor limits are exceeded etc.',
        webNotificationsDisabled: 'You have disabled web notifications for this site, to enable them go to your browser settings and set them to allow.',
        pushNotifications: 'Push Notifications',
        pushNotificationsDesc: 'Push notifications allow you to get notified even when the application is idle or in the background. <br>\n' +
            'Some examples of notifications are device errors, custom notifications when sensor limits are exceeded etc.',
        pushNotificationsDisabled: 'You have disabled push notifications for this app, to enable them go to your phone settings.',
        emailNotifications: 'Email Notifications',
        emailNotificationsDesc: 'Email notifications allow you to get notified about various errors on your device by email',
        maintenanceEmail: 'Maintenance Email',
        maintenanceEmailDesc: 'Enable if you want to be notified by email when a maintenance is going to occur.',
        other: 'Other',
        logout: 'Logout'
    },
    help: {
        header: 'Help',
        support: 'Support',
        supportDesc1: 'If you have any technical difficulties please feel free to contact us through our email',
        supportDesc2: 'Our staff will be ready to answer any of your questions.'
    },
    deviceInfo: {
        deviceHistory: 'Device History',
        chartLoadingData: 'Loading data from server...',
        relayActiveTime: 'Relay Active Time',
        energyProduction: 'Energy Production',
        variant: 'Variant',
        module: 'Module',
        schedule: 'Schedule',
        heat: 'Heat',
        cool: 'Cool',
        actual: 'Act.',
        relay: 'Relay',
        heatPump: 'Heat Pump',
        currentPower: 'Current Power',
        today: 'Today',
        thisMonth: 'This Month',
        thisYear: 'This Year'
    },
    deviceEdit: {
        general: 'General',
        deviceName: 'Device name',
        deviceDesc: 'Device description',
        firmwareVersion: 'Firmware version',
        muteNotifications: 'Mute notifications',
        dontMute: 'Don\'t mute',
        oneHour: '1 hour',
        sixHours: '6 hours',
        twelveHours: '12 hours',
        oneDay: '1 day',
        muteIndefinitely: 'Mute indefinitely',
        noNotificationMethodsPart1: 'You have not enabled any notification method.',
        noNotificationMethodsPart2: 'Go to account settings and enable a notification method if you want to start receiving notifications.',
        sensorNotificationLimits: 'Sensor notification limits',
        sensorNotificationLimitsDesc: 'Get notified when sensor temperature goes below or upward your set limits.',
        sensorsAndRelaysNamings: 'Sensors and relays namings',
        sensorsAndRelaysNamingsPart1: 'Name should be between',
        sensorsAndRelaysNamingsPart2: 'and',
        sensorsAndRelaysNamingsPart3: 'characters',
        deviceSharing: 'Device sharing',
        enterEmail: 'Enter email',
        share: 'Share',
        unshare: 'Unshare',
        viewer: 'Viewer',
        maintainer: 'Maintainer',
        accountNotFound: 'We could not find a registered account with the provided email address.',
        alreadyShared: 'You have shared this device with this email address.',
        maximumSharingNumber: 'You have reached maximum sharing number.',
        privilegeUpdateSuccess: 'Privilege updated successfully.',
        privilegeUpdateFail: 'Could not update user privilege.',
        solarSettings: 'Solar Settings',
        pumps: 'Pumps',
        modules: 'Modules',
        protections: 'Protections',
        system: 'System',
        collector: 'Collector',
        recooling: 'Recooling',
        frost: 'Frost',
        seizing: 'Seizing',
        storageAlarm: 'Storage Alarm',
        manualOperations: 'Manual Operations',
        setup: 'Setup',
        sensorConfig: 'Sensors Config',
        datetime: 'Datetime',
        holiday: 'Holiday',
        manageTemplates: 'Manage Templates',
        manageTemplatesDescV0: 'Save the current configured parameters of your physical device as a template which can be viewed later.',
        manageTemplatesDescV1: 'Managing your templates lets you save the current configured parameters of your physical device as a template\n' +
            ' which can be viewed later or load an existing template to your device.',
        save: 'Save',
        load: 'Load',
        saveTemplate: 'Save Template',
        enterTemplateName: 'Enter template name',
        templateSavedSuccessfully: 'Your template was saved successfully.',
        maxLimit: 'Max limit reached',
        noMoreThan20: 'You cannot save more than 20 templates.',
        fail: 'Fail',
        couldNotBeSaved: 'Your template could not be saved.',
        templateNameNoMoreThan20Chars: 'Template name must be no more than 20 characters',
        atLeast4Chars: 'Enter at least 4 characters',
        notEmpty: 'Template name must not be empty',
        ready: 'Ready',
        loadTemplate: 'Load Template',
        selectTemplateToLoad: 'Select a template that you want to load on your device.',
        view: 'View',
        loading: 'Loading...',
        parametersLoadedSuccessfully: 'All parameters were loaded successfully.',
        warning: 'Warning',
        followingParametersCouldNotBeSet: 'The following parameters could not be set:',
        notCompatible: 'Not compatible',
        selectAnOption: 'Select an option',
        resetEnergyData: 'Reset Energy Data',
        deleteDataProducedByDevice: 'Delete all data about the energy produced by this device.',
        reset: 'Reset',
        deleteEnergyData: 'Delete energy data',
        doYouWantToDeleteData: 'Are you sure you want to delete the energy data this device?',
        daily: 'Daily',
        weekly: 'Weekly',
        schedule: 'Schedule',
        forced: 'Forced',
        inverted: 'Inverted',
        normal: 'Normal',
        disabled: 'Disabled',
        thermostat: 'Thermostat',
        recycle: 'Recycle',
        fireplace: 'Fireplace',
        heatExchange: 'Heat Exchange',
        parallelRelay: 'Parallel Relay',
        flowSwitch: 'Flow Switch',
        alternate: 'Alternate',
        absolute: 'Absolute',
        yes: 'Si',
        heat: 'Heat',
        cool: 'Cool',
        changeVariant: 'Change variant',
        changeVariantDescPart1: 'Are you sure that you want to change the device variant?',
        changeVariantDescPart2: 'Doing so can make you lose some of the configurations you have set on the device.'
    },
    notificationsHeader: {
        offlineMsg: 'You are offline, please check your internet connection.',
        sampleData: 'Sample data',
        sampleDataTooltip: 'Since you have not linked a physical device to this virtual device the data shown is just a sample.',
        deviceNotLinked: 'Device is not linked',
        deviceNotLinkedTooltip: 'Since the device is not linked most of the data is the last snapshot from device state and read-only.',
        deviceOffline: 'Device is offline',
        manualMode: 'Device is in manual mode',
        sensorDefective: 'Sensor defective',
        systemProtection: 'System protection',
        collectorProtection: 'Collector protection',
        recoolingProtection: 'Recooling protection',
        frostProtection: 'Frost protection',
        seizeProtection: 'Seize protection',
        storageAlarm: 'Storage alarm'
    },
    resetPassword: {
        resetPassword: 'Reset Password',
        resetPasswordDesc: 'Enter new password for your account',
        newPassword: 'New password',
        repeatPassword: 'Repeat password',
        updatePassword: 'Update password',
        resetSuccess: 'Your password has been updated successfully.',
        errorOccurred: 'An error occurred.'
    }
};