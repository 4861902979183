import Vue from 'vue';
import i18n from "../locales/i18n";

module.exports = {
    checked: false,

    async checkForUpdates() {
        this.checked = true;
        let response = await Vue.prototype.$axios.get('/latest-mobile-app-version');
        let latestAppVersion = response.data;
        console.log('LAV: ' + latestAppVersion);
        console.log('AV: ' + Vue.prototype.$appVersion);

        if (Vue.prototype.$appVersion !== latestAppVersion) { //update available
            let message = i18n.t('general.mobileUpdateMessage');
            Vue.toasted.show(message, {
                theme: "toasted-primary",
                position: "top-center",
                duration: null,
                singleton: true,
                closeOnSwipe: false,
                fullWidth: false,
                action: [
                    {
                        text: i18n.t('general.update'),
                        onClick: (e, toastObject) => {
                            if (Vue.prototype.$platform === 'android') {
                                window.location.href = `market://details?id=${process.env.VUE_APP_PLAY_STORE_BUNDLE_ID}`;
                            } else if (Vue.prototype.$platform === 'ios') {
                                window.location.href = `itms-apps://itunes.apple.com/app/id${process.env.VUE_APP_STORE_BUNDLE_ID}`;
                            }

                            toastObject.goAway(0);
                        }
                    },
                    {
                        text: i18n.t('general.later'),
                        onClick: (e, toastObject) => {
                            toastObject.goAway(0);
                        }
                    }
                ]
            });
        }

    },

    hasCheckedForUpdates() {
        return this.checked;
    }
};
